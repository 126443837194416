const lang = {
    en: {
        TABLE_OF_CONTENTS: `Table of Contents`,
    },
    de: {
        TABLE_OF_CONTENTS: `Inhaltsverzeichnis`,
    },
}

module.exports = lang

/**
*
* Placeholder for SocialRss
*
*/

// The actual component
const SocialRss = () => (null)

export default SocialRss

